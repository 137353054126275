import { addSpanAtPos } from "../util/util";

export const header = {
  moto: "Contact us",
  heading: addSpanAtPos(["Get In", "Touch"], 1),
  description: `We will contact again after receive your request in 24h`,
  mailId: "info@starbaseinnovations.in",
  mobNum: "(+91) 9846 5181 78",
  address: [
    {
      id: { a: "data1", b: "head1" },
      item: "Registered Address",
      detail: `
Teamstarbase Pvt Ltd, Building No.2/11, Kedaram, Panayi, Kokkallor P O, Balussery, Kozhikode, Kerala – 673612`,
    },
    {
      id: { a: "data2", b: "head2" },
      item: "Current Address",
      detail:
         `
Teamstarbase Pvt Ltd, AIC-IIIT Kottayam, Valavoor P O, Pala, Kottayam, Kerala - 686635
`,
    },
  ],
};
