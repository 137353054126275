import { addSpanAtPos } from "../util/util";
import mission from "../assets/images/pageasset/mission.jpg";
import vision from "../assets/images/pageasset/vision.jpg";
import story from "../assets/images/pageasset/story.jpg";
import nithin from "../assets/images/team/nithin.png";
import akhil from "../assets/images/team/akhil.jpg";
import ahana from "../assets/images/team/ahana.png";
import jomal from "../assets/images/team/jomal.jpg";
import barinder from "../assets/images/team/barinder.jpg";
import sarath from "../assets/images/team/sarath.jpg";
export const header = {
  moto: "About Our Company",
  heading: addSpanAtPos(
    ["We Provide Smart Waste Management with our", "Software as a Service"],
    1
  ),
  description:
    "Starbase Innovations provide SaaS and AI solutions which can be integrated with IoT devices.",
};

export const sectionOne = [
  {
    id: 1,
    imageOn: "left",
    endImg: ["lines", "bubble"],
    containerClass: "frs-content",
    mainImg: mission,
    heading: addSpanAtPos(["Our ", "Mission"], 1),
    description: `The digital ecosystem is designed by integrating the various
                    processes between four layers of technology namely data
                    analytic, data input, Connectivity and Cloud infrastructure.
                    The integration of Distributed Ledger Technology (DLT) opens
                    a wide new spectrum of opportunities in designing the SaaS
                    solutions required for each vertical.`,
  },
  {
    id: 2,
    imageOn: "right",
    endImg: ["bubble2"],
    containerClass: "sec-content",
    mainImg: vision,
    heading: addSpanAtPos(["Our ", "Vision"], 1),
    description: `  To re-engineer the market ecosystem of India to a digital
                     ecosystem supporting a self sustainable model.`,
  },
  {
    id: 3,
    imageOn: "left",
    endImg: ["lines", "bubble"],
    containerClass: "frs-content",
    mainImg: story,
    heading: addSpanAtPos(["Our ", "Story"], 1),
    description: `  We as a team began this journey in 2016 with our first
                     startup, where we stepped into the world market to learn how
                     the system works and how we could navigate through it to
                     reach our goals. We were able to build a group of companies
                     working in sectors that depend on one another to sustain
                     themselves in India and Dubai. After the first Covid wave we
                     realized that our roots were not strong enough to sustain in
                     the coming market condition. From there we took on the
                     challenge of finding an innovative idea to help us get to
                     our goals. We rafted through the first two waves by
                     dissolving our involvement in earlier companies and were
                     able to successfully launch a Startup named Teamstarbase
                     INNOVATIONS registered under Teamstarbase Pvt Ltd. The
                     company was incorporated on 30th April 2021 and since then
                     we have come a long way from ideation to validation and we
                     are entering the traction stage of our startup journey
                     presently. We believe we can achieve our goals and vision,
                     as long as we trust each other through every phase of this
                     long journey.`,
  },
];

export const sectionTwo = {
  moto: "The Team Work",
  heading: addSpanAtPos(["Our ", "Team"], 1),
  description: "The team helps us to move forward in our journey",
};

export const portfolio = [
  {
    id: 1,
    name: "Nithin Anil",
    role: "CEO Founder",
    img: nithin,
    facebook: "https://www.facebook.com/nithin.anil",
    linkedIn: "https://www.linkedin.com/in/nithin-anil-1009a137/",
    instagram: "https://www.instagram.com/nithin_anil/",
  },
  {
    id: 2,
    name: "Barinder Sethi",
    role: "CSO",
    img: barinder,
    facebook: "https://www.facebook.com/",
    linkedIn: "https://www.linkedin.com/in/barinder-sethi-78a00b5/",
    instagram: "www.instagram.com/",
  },
  {
    id: 3,
    name: "Akhil Jayan",
    role: "CTO",
    img: akhil,
    facebook: "https://www.facebook.com/0akjn0",
    linkedIn: "https://www.linkedin.com/in/akhil-jayan/",
    instagram: "www.instagram.com/",
  },
  {
    id: 4,
    name: "Ahana K K",
    role: "Co-Founder",
    img: ahana,
    facebook: "https://www.facebook.com/ahana.padman.9",
    linkedIn: "https://www.linkedin.com/in/ahanakk/",
    instagram: "https://www.instagram.com/ahanapadman/",
  },
  {
    id: 5,
    name: "Jomal Jose",
    role: "Product Designer",
    img: jomal,
    facebook: "https://www.facebook.com/jomaljose.39/",
    linkedIn: "https://www.linkedin.com/in/jomal-jose-688b4597/",
    instagram: "https://www.instagram.com/jomaljose.design/",
  },
  {
    id: 6,
    name: "Sarath Lal",
    role: "Developer",
    img: sarath,
    facebook: "https://www.facebook.com/",
    linkedIn: "https://www.linkedin.com/in/sarath-lal-37b042226/",
    instagram: "https://www.instagram.com/sixfeeted_guy/",
  },
];
