/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import NavBar from "./NavBar";
import MainContent from "./MainContent";
import Footer from "./Footer";

const PageComponent = () => {
  const [activePage, setActivePage] = useState("home");

  return (
    <React.Fragment>
      <>
        <div id="preloader"></div>
      </>

      <>
        <div className="top-navbar style-4">
          <div className="container">
            <div className="content text-white"></div>
          </div>
        </div>
      </>

      <>
        <NavBar
          active={activePage}
          onNavigate={(page) => {
            setActivePage(page);
          }}
        />
      </>

      <>
        <MainContent active={activePage} />
      </>

      <>
        <Footer
          active={activePage}
          onNavigate={(page) => {
            setActivePage(page);
          }}
        />
      </>

      <>
        <a className="to_top bg-gray rounded-circle icon-40 d-inline-flex align-items-center justify-content-center">
          <i className="bi bi-chevron-up fs-6 text-dark"></i>
        </a>
      </>
    </React.Fragment>
  );
};

export default PageComponent;
