import { header, sectionOne, sectiontwo } from "../data/portfolioData";
import { Link } from "react-router-dom";

const PortfolioPage = ({ active }) => {
  const renderSectionOne = (hdata, data) => {
    return (
      <section className="about section-padding style-4 pb-20 pt-20">
        <div className="integration">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-head text-center style-4">
                <small className="title_small">{hdata.moto}</small>
                <h2 className="mb-20">
                  <span> {hdata.heading} </span>
                </h2>
                <p>{hdata.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="content frs-content py-4">
          <div className="container">
            <div className="row portfolio style-1 align-items-center">
              {data.cards.map((i) => (
                <div className="col-lg-4">
                  <div className="portfolio-card mb-50">
                    <div className="img full-center">
                      <img className="img-200" src={i.img} alt="" />
                    </div>
                    <div className="info">
                      <h5>
                        <a href="page-single-project-app1.html">{i.heading}</a>
                      </h5>
                      <small className="d-block color-main text-uppercase">
                        {i.subHeading}
                      </small>
                      <div className="text">{i.description}</div>

                      <div className="tags">
                        {i.tags.map((j) => (
                          <a href={j.link}>{j.tag}</a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img
            src={require("../assets/images/system/about_s4_lines.png")}
            alt=""
            className="lines"
          />
          <img
            src={require("../assets/images/system/about_s4_bubble.png")}
            alt=""
            className="bubble"
          />
        </div>
      </section>
    );
  };

  const renderSectionTwo = (data) => {
    return (
      <section className="download section-padding style-5 bg-wight">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="content text-center">
                <div className="section-head text-center style-4">
                  <h2 className="mb-20">{data.heading}</h2>
                </div>
                <div className="butns mt-70">
                  {data.buttons.map((i) => (
                    <Link to={i.link} className={i.class}>
                      <small>{i.label}</small>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={require("../assets/images/system/contact_globe.png")}
          alt=""
          className="contact_globe"
        />
      </section>
    );
  };

  return (
    <main className="portfolio-page style-1">
      {renderSectionOne(header, sectionOne)}
      {renderSectionTwo(sectiontwo)}
    </main>
  );
};

export default PortfolioPage;
