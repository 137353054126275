import React, { useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";
import Swal from "sweetalert2";
import BounceLoader from "react-spinners/BounceLoader";
import { header } from "../data/contactUsData";

/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
const ContactUsPage = ({ active }) => {
  const [loading, setLoading] = useState(false);

  const emailBaseUrl =
    "https://swastha.starbaseinnovations.in:8443/api/util/send_enquiry";

  const formik = useFormik({
    initialValues: {
      name: undefined,
      email: undefined,
      phoneNumber: undefined,
      website: undefined,
      category: undefined,
      message: undefined,
      // agreeTerms: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }

      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = "Required";
      } else if (!/^[0-9]*$/i.test(values.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number";
      } else if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = "Must be of 10 characters";
      }

      if (!values.category) {
        errors.category = "Required";
      }

      if (!values.message) {
        errors.message = "Required";
      } else if (values.message.length < 5) {
        errors.message = "Must be 5 characters or more";
      }

      // if (!values.agreeTerms) {
      //   errors.agreeTerms = "Required";
      // }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    const payload = {
      to: null,
      from: values.email,
      fromName: values.name,
      fromContact: values.phoneNumber,
      subject: values.category,
      message: values.message,
    };
    setLoading(true);
    axios
      .post(emailBaseUrl, payload)
      .then((response) => {
        Swal.fire(
          "Thanks for Reaching out!",
          "We will comeback to you in 24hrs!",
          "success"
        );
      })
      .catch(() => {
        Swal.fire(
          "Sorry Could not process your request!",
          "Please contact us via phone number. Sorry for the inconvenience",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPlaceholder = (key) => {
    switch (key) {
      case "name":
        return "Name *";
      case "email":
        return "Email *";
      case "phoneNumber":
        return "Phone Number *";
      case "website":
        return "Your Website (Optional)";
      case "category":
        return "Category *";
      case "message":
        return "How can we help you? *";
      default:
        return "";
    }
  };

  const renderFormItem = (key) => {
    if (["name", "email", "phoneNumber", "website"].includes(key)) {
      return (
        <div className="col-lg-6" key={key}>
          <div className="form-group mb-20">
            <input
              type="text"
              name={key}
              id={key}
              value={formik.values[key]}
              onChange={formik.handleChange}
              className={classNames(
                "form-control",
                !formik.isValid ? "invalid-field" : false
              )}
              placeholder={getPlaceholder(key)}
            />
            {formik.errors[key] && !formik.isValid && (
              <span className="error-message">{formik.errors[key]}</span>
            )}
          </div>
        </div>
      );
    }

    if (key === "category") {
      return (
        <div className="col-lg-12" key={key}>
          <div className="form-group mb-20">
            <select
              name={key}
              id={key}
              onChange={formik.handleChange}
              className={classNames(
                "form-select",
                !formik.isValid ? "invalid-field" : false
              )}
            >
              <option value={undefined}>-- Select a Category --</option>
              <option value="Enquiry">Enquiry</option>
              <option value="Support">Support</option>
            </select>
            {formik.errors[key] && !formik.isValid && (
              <span className="error-message">{formik.errors[key]}</span>
            )}
          </div>
        </div>
      );
    }

    if (key === "message") {
      return (
        <div className="col-lg-12" key={key}>
          <div className="form-group">
            <textarea
              rows="10"
              name={key}
              id={key}
              onChange={formik.handleChange}
              value={formik.values[key]}
              className={classNames(
                "form-control",
                !formik.isValid ? "invalid-field" : false
              )}
              placeholder={getPlaceholder(key)}
            ></textarea>
            {formik.errors[key] && !formik.isValid && (
              <span className="error-message">{formik.errors[key]}</span>
            )}
          </div>
        </div>
      );
    }

    // if(key === 'agreeTerms'){
    //   return (
    //     <div className="col-lg-12 text-center">
    //       <div className="form-check d-inline-flex mt-30 mb-30">
    //         <input
    //           className="form-check-input me-2 mt-0"
    //           type="checkbox"
    //           value=""
    //           id="flexCheckDefault"
    //         />
    //         <label
    //           className="form-check-label small"
    //           htmlFor="flexCheckDefault"
    //         >
    //           By submitting, i'm agreed to the{" "}
    //           <a href="#" className="text-decoration-underline">
    //             Terms & Conditons
    //           </a>
    //         </label>
    //       </div>
    //     </div>
    //   )
    // }
  };

   const renderAccordian = (items) => {
     const accitems = items.map((data, index) => {
       const is0 = index === 1;
       const divBase = "accordion-collapse collapse";
       const divCls = classNames(divBase, is0 ? "show" : "");
       const btnClass = classNames("accordion-button", is0 ? "" : "collapsed");

       return (
         <div className="accordion-item">
           <h2 className="accordion-header" id={data.id.b}>
             <button
               className={btnClass}
               type="button"
               data-bs-toggle="collapse"
               data-bs-target={`#${data.id.a}`}
               aria-expanded="true"
               aria-controls={data.id.a}
             >
               {data.item}
             </button>
           </h2>
           <div
             id={data.id.a}
             className={divCls}
             aria-labelledby={data.id.b}
             data-bs-parent="#dataAccordion"
           >
             <div className="accordion-body">{data.detail}</div>
           </div>
         </div>
       );
     });

     return (
       <div className="faq style-3 style-4">
         <div className="accordion" id="dataAccordion">
           {accitems}
         </div>
       </div>
     );
   };

  const renderSectionOne = (data) => {
    return (
      <section className="community section-padding style-5">
        <div className="container">
          <div className="section-head text-center style-4 mb-40">
            <small className="title_small">{data.moto}</small>
            <h2 className="mb-20">{data.heading}</h2>
            <p>{data.description}</p>
          </div>
          <div className="content rounded-pill">
            <div className="commun-card">
              <div className="icon icon-45">
                <img
                  src={require("../assets/images/icons/mail3d.png")}
                  alt=""
                />
              </div>
              <div className="inf">
                <h5>{data.mailId} </h5>
              </div>
            </div>
            <div className="commun-card">
              <div className="icon icon-45">
                <img src={require("../assets/images/icons/map3d.png")} alt="" />
              </div>
              <div className="inf">
                <h5>Kochi,Kerala,India</h5>
              </div>
            </div>
            <div className="commun-card">
              <div className="icon icon-45">
                <img src={require("../assets/images/icons/msg3d.png")} alt="" />
              </div>
              <div className="inf">
                <h5>{data.mobNum}</h5>
              </div>
            </div>
          </div>
          <div>{renderAccordian(data.address)}</div>
        </div>
      </section>
    );
  };

  const btnStyle = {
    width: "25%",
    height: "45px",
  };

  const renderSectionTwo = () => {
    return (
      <section className="contact section-padding pt-0 style-6">
        <div className="container">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <p className="text-center text-danger fs-12px mb-30">
                    The field is required mark as *
                  </p>
                  <div className="row">
                    {[
                      "name",
                      "email",
                      "phoneNumber",
                      "website",
                      "category",
                      "message",
                    ].map((key) => {
                      return renderFormItem(key);
                    })}
                    <div
                      className="col-lg-12 text-center"
                      style={{ marginTop: "10px" }}
                    >
                      {loading ? (
                        <div
                          style={btnStyle}
                          className="btn rounded-pill bg-blue4 fw-bold text-white text-light fs-12px"
                        >
                          <BounceLoader color="#a6bc42" size={20} />
                        </div>
                      ) : (
                        <input
                          style={btnStyle}
                          type="submit"
                          value="Send Your Request"
                          className="btn rounded-pill bg-blue4 fw-bold text-white text-light fs-12px"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <img
              src={require("../assets/images/icons/contact_a.png")}
              alt=""
              className="contact_a"
            />
            <img
              src={require("../assets/images/icons/contact_message.png")}
              alt=""
              className="contact_message"
            />
          </div>
        </div>
      </section>
    );
  };

  const renderMap = () => {
    return (
      <div className="full-center">
        <div
          className="map"
          style={{
            margin: "0 90px",
            borderRadius: "20px",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3932.13500351176!2d76.64793971479219!3d9.754630293019673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07cf6609f3ecd1%3A0x7b40fcce1a9708dc!2sAIC-IIITKottayam!5e0!3m2!1sen!2sin!4v1683609243573!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <main className="contact-page style-5">
      {renderSectionOne(header)}
      {renderSectionTwo()}
      {renderMap()}
    </main>
  );
};

export default ContactUsPage;
