import { addBreakLine, addSpanAtPos } from "../util/util";
import feederRoots from "../assets/images/logos/feeder-roots.png";
import binRecyclers from "../assets/images/logos/bin-recyclers.png";
import zeroWaste from "../assets/images/logos/ccc-zero-waste.png";
import standardGreen from "../assets/images/logos/standard-green-energy.png";
import plastRoots from "../assets/images/logos/plastroots.png";
import unwaste from "../assets/images/logos/unwaste.png";

export const header = {
  moto: "Portfolio",
  heading: addSpanAtPos(["Use Cases"], 0),
  description: `Take a look at our portfolio where we implemented our project
                  on.`,
};

export const sectionOne = {
  cards: [
    {
      id: 1,
      img: feederRoots,
      heading: "Feeder Roots, Kozhikode, Kerala",
      subHeading: "Software Solutions",
      description:
        "Our SaaS solution was implemented for 5 tonnes of drywaste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "Drywaste",
          link: "#",
        },
      ],
    },
    {
      id: 2,
      img: binRecyclers,
      heading: "Bin Recyclers, Trivandrum, Kerala",
      subHeading: " Software Solutions, Hardware Solutions",
      description:
        "Our technology was implemented for a 10 tonnes of wet waste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "IoT",
          link: "#",
        },
        {
          tag: "Wetwaste",
          link: "#",
        },
        {
          tag: "OS",
          link: "#",
        },
      ],
    },
    {
      id: 3,
      img: zeroWaste,
      heading: "CCC Zero Waste, Kochi, Kerala",
      subHeading: " Software Solutions, Hardware Solutions",
      description:
        "Our technology was implemented for a 10 tonnes of dry waste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "Dry Waste",
          link: "#",
        },
      ],
    },
    {
      id: 4,
      img: standardGreen,
      heading: "Standard Green Energy, Kasaragod, Kerala",
      subHeading: " Software Solutions",
      description:
        "Our technology was implemented for a 10 tons of chicken waste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "Chicken Waste",
          link: "#",
        },
      ],
    },
    {
      id: 5,
      img: plastRoots,
      heading: "Plastroots, Nagpur, Maharashtra",
      subHeading: " Software Solutions, Hardware Solutions",
      description:
        "Our technology was implemented for an MRF processing 10 tons of plastic waste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "Plastic Waste",
          link: "#",
        },
      ],
    },
    {
      id: 6,
      img: unwaste,
      heading: "Unwaste Network, Coimbatore, Tamil Nadu",
      subHeading: " Software Solutions",
      description:
        "Our technology was implemented for a 10 tonnes of dry waste per day.",
      tags: [
        {
          tag: "SaaS",
          link: "#",
        },
        {
          tag: "Dry Waste",
          link: "#",
        },
      ],
    },
  ],
  endImg: ["lines", "bubble"],
};

const hdata = addSpanAtPos(
  ["Update your waste management system to ", " Become Smarter", " with "],
  1
);

export const sectiontwo = {
  heading: addBreakLine([hdata, "our solutions"]),
  buttons: [
    {
      label: "Contact Us",
      link: "/contact",
      class: "btn rounded-pill bg-blue4 fw-bold text-white me-4",
    },
    {
      label: "See Pricing & Plan",
      link: "/pricing",
      class: "btn rounded-pill hover-blue4 fw-bold border-blue4",
    },
  ],
  endImg: ["globe"],
};
