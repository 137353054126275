import { addSpanAtPos, addBreakLine } from "../util/util";
import swasthaLogo from "../assets/images/logos/swastha.png";
import saasImg from "../assets/images/icons/saas-solution.png";
import hardwareImg from "../assets/images/icons/hardware-solution.png";

import custInterferance from "../assets/images/icons/customer-interface.png";
import driverInterferance from "../assets/images/icons/driver-interface.png";
import operationInterferance from "../assets/images/icons/operations-interface.png";
import adminInterferance from "../assets/images/icons/admin-interface.png";

import smartBin from "../assets/images/icons/smart-bin.png";
import iot from "../assets/images/icons/iot.png";
import gps from "../assets/images/icons/gps-tracking.png";
import node from "../assets/images/icons/node-tracking.png";

import scr1 from "../assets/images/screenshots/1.png";
import scr2 from "../assets/images/screenshots/2.png";
import scr3 from "../assets/images/screenshots/3.jpg";
import scr4 from "../assets/images/screenshots/4.png";
import scr5 from "../assets/images/screenshots/5.png";
import hand from "../assets/images/screenshots/hand.png";

export const header = {
  moto: "Waste Management Solution",
  heading: addSpanAtPos(["Swastha", "1.0"], 0),
  description: `Swastha 1.0 is a smart waste management system using
                      proven technologies such as SaaS, IoT devices and Data
                      Analytics. Our solution will deliver features that will
                      evolve your infrastructure.`,
  logo: swasthaLogo,
  fheading: addSpanAtPos(["Swastha ", "Features"], 1),
  keyPoints: [
    {
      title: "Swastha SaaS Solutions",
      imgSrc: saasImg,
    },
    {
      title: "Swastha Hardware Solutions",
      imgSrc: hardwareImg,
    },
  ],
};

export const sectionOne = {
  heading: addSpanAtPos(["Software as a Service"], 0),
  description: ` Swastha provides the most efficient cloud based solution for
                  smart waste management no matter the size and type of your
                  business. With the help of realtime data obtained by seamless
                  integration of IoT devices, the entire hierarchy of SaaS
                  platform is powered using its algorithm at the core. Swastha
                  covers the entire stages of waste management using four level
                  of interfaces and provides environmentally friendly solutions.`,
  features: [
    {
      id: 1,
      imageOn: "left",
      endImg: ["lines", "bubble"],
      containerClass: "frs-content py-4",
      mainImg: custInterferance,
      heading: addSpanAtPos(["Customer ", "Interface"], 1),
      description: `The customer interface helps the end user to request and
                    track waste collection. The interface is a perfect companion
                    for end user to facilitate their requirements such as
                    payment, request history and tracking. The customer
                    interface is only available in mobile application.`,
    },
    {
      id: 2,
      imageOn: "right",
      endImg: ["bubble2"],
      containerClass: "sec-content py-4",
      mainImg: driverInterferance,
      heading: addSpanAtPos(["Driver ", "Interface"], 1),
      description: `The driver interface is used to view request from customers
                    assigned to them. The interface also provides a navigation
                    support to the collection agents. The driver interface shows
                    all the collection history with filtering option based on
                    waste category. The driver interface is available in both
                    mobile and web application.`,
    },
    {
      id: 3,
      imageOn: "left",
      endImg: ["lines", "bubble"],
      containerClass: "frs-content py-4",
      mainImg: operationInterferance,
      heading: addSpanAtPos(["Operation ", "Interface"], 1),
      description: ` The operation interface is used to track and locate drivers
                    and customer nodes. The operation interface has an
                    additional functionality to confirm and add weight for
                    segregated waste. The operation interface is only available
                    in web application.`,
    },
    {
      id: 4,
      imageOn: "right",
      endImg: ["bubble2"],
      containerClass: "sec-content py-4",
      mainImg: adminInterferance,
      heading: addSpanAtPos(["Admin ", "Interface"], 1),
      description: `The super admin interface has the authority to allocate,
                    edit and remove nodes for all users in the hierarchy. It can
                    also be used to monitor all the process flow in the system
                    and also provides analysis and reports on waste management
                    infrastructure. Super Admin Interface is only available in
                    web application.`,
    },
  ],
};

export const sectionTwo = {
  heading: addSpanAtPos(["Hardware Devices"], 0),
  description: `Starbase Innovation is able to provide a comprehensive AI
                  based solution for smart waste management no matter the size
                  and type of the infrastructure. With the seamless integration
                  of cutting-edge devices meeting Indian standards. Swastha
                  hardware covers the entire waste process by feeding realtime
                  data to its software counterpart. The hardware is integrated
                  at every stage from the collection phase to treatment and
                  recycling.`,
  features: [
    {
      id: 1,
      imageOn: "left",
      endImg: ["bubble"],
      containerClass: "frs-content",
      mainImg: smartBin,
      heading: addSpanAtPos(["Smart ", "Bin"], 1),
      description: `Our Smart Bins are equipped with a level sensor to monitor
                    the volume of waste inside. It convey the level of bin by
                    four levels.`,

      fheading: addBreakLine(["Features", ""], 0),
      content: addBreakLine(
        [
          "Capacity: 60 Litres to 200 Litres",
          "Sensor: ToF (Time of Flight)",
          "Communication: 3G (GSM, UMTS) or 4G (LTE)",
          "Power: 3.6V high performance lithium battery",
        ],
        0,
        1,
        2
      ),
    },
    {
      id: 2,
      imageOn: "right",
      endImg: ["bubble2"],
      containerClass: "sec-content",
      mainImg: iot,
      heading: addSpanAtPos(["Volume Measuring", "IoT Device"], 1),
      description: `Our Volume Measuring IoT devices provide weight data to the
                    cloud. This data can be recorded on different stages of
                    waste management processes like segregation ground,
                    recycling plant and so on.`,

      fheading: addBreakLine(["Features", ""], 0),
      content: addBreakLine(
        [
          "Capacity: 20 Tonnes",
          "Sensor: Load Cell",
          "Communication: 3G (GSM, UMTS) or 4G (LTE)",
          " Power: 240V AC power source",
        ],
        0,
        1,
        2
      ),
    },
    {
      id: 3,
      imageOn: "left",
      endImg: ["bubble"],
      containerClass: "frs-content",
      mainImg: gps,
      heading: addSpanAtPos(["GPS", "Tracker"], 1),
      description: `The GPS Trackers will be equipped on the collection
                    vehicles. The GPS trackers send real time data of location
                    to the cloud. This can be integrated with our Driver
                    Interface to provide navigation.`,

      fheading: addBreakLine(["Features", ""], 0),
      content: addBreakLine(
        [
          " Sensor: GPS",
          "Communication: 3G (GSM, UMTS) or 4G (LTE)",
          "Power: 9V to 28V",
        ],
        0,
        1
      ),
    },
    {
      id: 4,
      imageOn: "right",
      endImg: ["bubble2"],
      containerClass: "sec-content",
      mainImg: node,
      heading: addSpanAtPos(["Node", "Tracker"], 1),
      description: ` Node Tracker is a GPS tracker used for stationary assets
                    like segregation ground, recycling plant and end users
                    without bin. The Node Tracker data communication interval is
                    more than a GPS Tracker.`,

      fheading: addBreakLine(["Features", ""], 0),
      content: addBreakLine(
        [
          " Sensor: GPS",
          "Communication: 3G (GSM, UMTS) or 4G (LTE)",
          "Power: 3.6V high performance lithium battery",
        ],
        0,
        1
      ),
    },
  ],
};

export const sectionThree = {
  heading: addSpanAtPos(["Get our ", "brochure for Swastha 1.0"], 1),
  content: "Download Brochure",
};

export const sectionFour = {
  imgData: [
    {
      img: scr1,
    },
    {
      img: scr2,
    },
    {
      img: scr3,
    },
    {
      img: scr4,
    },
    {
      img: scr5,
    },
  ],
  mainImg: hand,
};
