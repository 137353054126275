import { addSpanAtPos, addBreakLine } from "../util/util";
import headerImage from "../assets/images/system/header.png";

import binRecyclers from "../assets/images/logos/bin-recyclers.png";
import feederRoots from "../assets/images/logos/feeder-roots.png";
import zeroWaste from "../assets/images/logos/ccc-zero-waste.png";
import standardGreen from "../assets/images/logos/standard-green-energy.png";
import plastRoots from "../assets/images/logos/plastroots.png";
import unwaste from "../assets/images/logos/unwaste.png";


import analyticsDashboard from "../assets/images/icons/analytics-dashboard.png";
import digitalSolution from "../assets/images/icons/digital-solutions.png";
import easyIntegration from "../assets/images/icons/easy-integration.png";
import e2eFlow from "../assets/images/icons/end-to-end-flow.png";
import safetyFeature from "../assets/images/icons/safety-features.png";
import featImage from "../assets/images/system/feat_circle.png";

import ipad from "../assets/images/system/ipad.png";
import mobiles2 from "../assets/images/system/2mobiles.png";
import map from "../assets/images/system/map.png";

import smartBin from "../assets/images/icons/smart-bin.png";
import iot from "../assets/images/icons/iot.png";
import customers from "../assets/images/icons/customers.png";
import nodeTrack from "../assets/images/icons/node-tracking.png";
import gpsTrack from "../assets/images/icons/gps-tracking.png";
import intg from "../assets/images/system/intg_back.png";

import c01 from "../assets/images/icons/C-01.png";
import c04 from "../assets/images/icons/C-04.png";
import cl1 from "../assets/images/testimonials/cl1.png";
import cl2 from "../assets/images/testimonials/cl2.png";
import cl3 from "../assets/images/testimonials/cl3.png";
import contactGlb from "../assets/images/system/contact_globe.svg";

import aic from "../assets/images/logos/aic.png";
import nitiayog from "../assets/images/logos/niti-aayog.png";
import startindia from "../assets/images/logos/startup-india.png";
import nsrcel from "../assets/images/logos/nsrcel.png";
import gaiax from "../assets/images/logos/gaia-x.png";
import ginsep from "../assets/images/logos/ginsep.png";
import wfglobal from "../assets/images/logos/wfglobal.png";
import villgro from "../assets/images/logos/villgro.png";
import amritatbi from "../assets/images/logos/amritatbi.png";
import unicef from "../assets/images/logos/unicef.png";
import startkerala from "../assets/images/logos/kerala-startup.png";
import wave from "../assets/images/system/about_s4_wave.png";

const transform = "rotate(180deg)";

export const header = {
  moto: "Time to Evolve",
  heading: addSpanAtPos(
    ["Mission is to ", "Integrate the Power ", "of Innovation"],
    1
  ),
  description: addBreakLine([
    "Our team is driven by the motto of time to evolve and we work toward",
    "integrating future technology into solving the existing problems,",
    "in the most sustainable way.",
  ]),
  headerImage,
};

export const sectionOne = {
  heading: addSpanAtPos(["Customers ", "Who Trust Us"], 1),
  customers: [
    { id: 1, name: "CREDAI Clean City", imgSrc: zeroWaste, href: "" },
    { id: 2, name: "Feeder Roots", imgSrc: feederRoots, href: "" },
    { id: 3, name: "Bin Recyclers", imgSrc: binRecyclers, href: "" },
    { id: 4, name: "Standard Green Energy", imgSrc: standardGreen, href: "" },
    { id: 5, name: "Plastroots", imgSrc: plastRoots, href: "" },
    { id: 6, name: "Unwaste", imgSrc: unwaste, href: "" },
  ],
};

export const sectionTwo = {
  title: "Swastha 1.0 - Waste Management Solution",
  heading: addSpanAtPos(["Swastha ", "Features"], 1),
  features: [
    {
      id: 1,
      name: addBreakLine(["Analytics ", "and Dashboard"]),
      imgSrc: analyticsDashboard,
    },
    {
      id: 2,
      name: addBreakLine(["Digital ", "Solution"]),
      imgSrc: digitalSolution,
    },
    {
      id: 3,
      name: addBreakLine(["Easy ", "Integration"]),
      imgSrc: easyIntegration,
    },
    {
      id: 4,
      name: addBreakLine(["End to End ", "Operational Workflow"]),
      imgSrc: e2eFlow,
    },
    {
      id: 5,
      name: addBreakLine(["Advanced Safety ", "Features"]),
      imgSrc: safetyFeature,
    },
  ],
  endImg: featImage,
  endImgClass: "img-circle",
};

export const sectionThree = [
  {
    id: 1,
    imageOn: "left",
    endImg: ["bubble", "lines"],
    containerClass: "frs-content",
    mainImg: ipad,
    title: "Swastha 1.0 - Operations and Admin",
    heading: addSpanAtPos(["Swastha for ", "Waste Management"], 1),
    description: `The operation interface is used to track and locate drivers
        and customer nodes. The admin interface has the authority to
        allocate, edit and remove nodes for all users in the
        hierarchy. It can also be used to monitor all the process
        flow in the system and also provides analysis and reports on
        waste management infrastructure.`,
    items: [
      { item: "faTag", detail: "Track customers and drivers" },
      { item: "faSync", detail: "View analysis and reports realtime" },
      { item: "faTextWidth", detail: "Manage stages of waste management" },
    ],
    itemsType: "bullet",
    learnMoreLink: "",
  },
  {
    id: 2,
    imageOn: "right",
    endImg: ["bubble2"],
    containerClass: "sec-content",
    mainImg: mobiles2,
    title: " Swastha 1.0 - Customers and Drivers",
    heading: addSpanAtPos(["Swastha for ", "Waste Collection"], 1),
    description: `The customer and driver interface helps the users to request and track waste collection.`,
    items: [
      {
        id: { a: "data1", b: "head1" },
        item: "Request Waste Collection",
        detail: `The customer interface helps the end user to request and track waste collection.`,
      },
      {
        id: { a: "data2", b: "head2" },
        item: "Payment Interface",
        detail:
          "The end users can make payment for availing the services using UPI and payments.",
      },
      {
        id: { a: "data3", b: "head3" },
        item: "View and Track Collection Request",
        detail: `The driver interface is used to view request from
                  customers assigned to them. The interface also
                  provides a navigation support to the collection
                  agents.`,
      },
    ],
    itemsType: "accordion",
    learnMoreLink: "",
  },
  {
    id: 3,
    imageOn: "left",
    endImg: ["bubble"],
    containerClass: "trd-content",
    mainImg: map,
    title: "Swastha 1.0 - Security",
    heading: addSpanAtPos(["Safe with ", "Safety Features"], 1),
    description: `Swastha’s advanced safety features make sure that your data stay safe.`,
    items: [
      { item: "", detail: "Robust authentication" },
      { item: "", detail: "Role based access control" },
      { item: "", detail: "Data protection" },
    ],
    itemsType: "bullet",
    learnMoreLink: "",
  },
];

export const iotSection = {
  title: "Swastha 1.0 - Hardwares",
  heading: addSpanAtPos(["Integration With ", "IoT Devices"], 1),
  description: addBreakLine([
    "Swastha hardware covers the entire waste process by feeding realtime data to its software counterpart.",
    "The hardware is integrated at every stage from the collection phase to treatment and recycling.",
  ]),
  imgs: [
    { cls: "mt-30", imgSrc: smartBin },
    { cls: "mt-60", imgSrc: iot },
    { cls: "mt-20", imgSrc: customers },
    { cls: "mt-80", imgSrc: nodeTrack },
    { cls: "", imgSrc: gpsTrack },
  ],
  endImg: intg,
  endImgClass: "intg-back",
};

export const sectionFour = {
  title: "Testimonials",
  heading: addSpanAtPos(["Why People ", "Choose Swastha"], 1),
  description: `Our clients love Swastha. Let's hear what they have to say about us.`,
  details: [
    {
      icon: c01,
      h2Item: "2,5M+",
      summary: addBreakLine(["Tonnes of", "Waste Processed"]),
    },
    { icon: c04, h2Item: "200+", summary: "End Customers" },
  ],
  learnMoreLink: "",
  client: [
    {
      imgSrc: cl1,
      rating: 5,
      comment: addBreakLine([
        "“Their solution promises a smart way to overcome",
        "the disadvantages of existing Waste Management System”",
      ]),
      name: "George T Jose",
      designation: addSpanAtPos(["Director at ", "David and Zalin Limited"], 1),
    },
    {
      imgSrc: cl2,
      rating: 5,
      comment: addBreakLine([
        "“Swastha helped our MRF centres work efficiently.",
        "The platform is highly customizable and configurable.”",
      ]),
      name: "Kapil Jangale",
      designation: addSpanAtPos(
        [
          "Managing Director ","Plastroots Waste Management & Solutions Pvt. Ltd",
        ],
        1
      ),
    },
    {
      imgSrc: cl3,
      rating: 5,
      comment:
        "“Tracking our customers and vehicles are now easy. Swastha gives me full control of our ecosystem.”",
      name: "Prashob",
      designation: addSpanAtPos(["Director at ", "Feeder Roots LLP"], 1),
    },
  ],
  endImg: contactGlb,
  endImgClass: "testi-globe",
};

export const sectionFive = {
  heading: addSpanAtPos(["Our ", "Ecosystem Partners "], 1),
  items: [
    { imgSrc: aic, url: "http://icentre.iiitkottayam.ac.in/" },
    { imgSrc: nitiayog, url: "https://www.niti.gov.in/" },
    { imgSrc: startindia, url: "https://www.startupindia.gov.in/" },
    { imgSrc: nsrcel, url: "https://nsrcel.org/" },
    { imgSrc: gaiax, url: "https://gaia-x.eu/" },
    { imgSrc: ginsep, url: "https://ginsep.co/" },
    { imgSrc: wfglobal, url: "https://www.wfglobal.org/" },
    { imgSrc: villgro, url: "https://villgro.org/" },
    { imgSrc: amritatbi, url: "https://www.amritatbi.com/" },
    { imgSrc: unicef, url: "assets/images/logos/unicef.png" },
    { imgSrc: startkerala, url: "https://startupmission.kerala.gov.in/" },
  ],
  topBgImg: {
    imgSrc: wave,
    imgClass: "top-wave",
    css: { transform, width: "100%", bottom: "-3px", position: "relative" },
  },
  bottomBgImg: {
    imgSrc: wave,
    imgClass: "top-wave",
    css: { width: "100%", bottom: "6px", position: "relative" },
  },
};

export const sectionSix = {
  title: "Social Networks",
  heading: addSpanAtPos(["Support ", "Our Organisation"], 1),
  items: [
    {
      icon: "faLinkedin",
      name: "LinkedIn",
      summary: "Organisational & Career",
      url: "https://www.linkedin.com/company/82308216",
    },
    {
      icon: "faTwitter",
      name: "Twitter",
      summary: "Latest News & Updates",
      url: "https://twitter.com/Starbase_in",
    },
    {
      icon: "faFacebook",
      name: "Facebook",
      summary: "Social Page",
      url: "https://www.facebook.com/Starbase-Innovations-100189316079929",
    },
  ],
};
