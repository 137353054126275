import { addBreakLine, addSpanAtPos } from "../util/util";

import crownIcon from "../assets/images/icons/price_s4_2.png";

export const header = {
  moto: "Subscription Plans",
  heading: addSpanAtPos(["Our ", " Subscription Packages "], 1),
  description:
    "Take a look at our portfolio where we implemented our project on.",
};

export const sectionOne = {
  cards: [
    {
      id: 1,
      level: "BASIC",
      price: "0",
      description: "Select if you want demo for One Month",
      listClass: "d-flex align-items-center mb-3",
      iconClass:
        "icon-30 bg-blue4 rounded-circle text-white d-inline-flex align-items-center justify-content-center me-3 flex-shrink-0",
      points: [
        {
          point: "50 Customers",
        },
        {
          point: "1 Driver",
        },
        {
          point: "1 Operation",
        },
        {
          point: "0 Admin",
        },
      ],
      link: [
        {
          label: "Contact Us",
          path: "/contact",
          class: "btn rounded-pill hover-blue4 fw-bold mt-50 px-5 border-blue4",
        },
      ],
    },
    {
      id: 2,
      level: "ADVANCED",
      icon: crownIcon,
      price: "INR 5000",
      description: "Great starter pack for 12 Months",
      listClass: "d-flex align-items-center mb-3",
      iconClass:
        "icon-30 bg-blue4 rounded-circle text-white d-inline-flex align-items-center justify-content-center me-3 flex-shrink-0",
      points: [
        {
          point: "500 Customers",
        },
        {
          point: "2 Driver",
        },
        {
          point: "1 Operation",
        },
        {
          point: "1 Admin",
        },
      ],
      link: [
        {
          label: "Contact Us",
          path: "/contact",
          class: "btn rounded-pill bg-blue4 fw-bold text-white px-5 mt-50",
        },
      ],
      off: addBreakLine(["20%", "off"]),
    },
    {
      id: 3,
      level: "PROFESSIONAL",
      price: "INR 12000",
      description: "Convinient pack for 12 Months",
      listClass: "d-flex align-items-center mb-3",
      iconClass:
        "icon-30 bg-blue4 rounded-circle text-white d-inline-flex align-items-center justify-content-center me-3 flex-shrink-0",
      points: [
        {
          point: "1500 Customers",
        },
        {
          point: "5 Driver",
        },
        {
          point: "2 Operation",
        },
        {
          point: "1 Admin",
        },
      ],
      link: [
        {
          label: "Contact Us",
          path: "/contact",
          class: "btn rounded-pill hover-blue4 fw-bold mt-50 px-5 border-blue4",
        },
      ],
    },
  ],
  endImg: ["lines", "bubble"],
};
