import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../pages/HomePage";
import AboutPage from "../../pages/AboutPage";
import SwasthaPage from "../../pages/SwasthaPage";
import PortfolioPage from "../../pages/PortfolioPage";
import PricingPage from "../../pages/PricingPage";
import ContactUsPage from "../../pages/ContactUsPage";

const MainContent = ({ active }) => {
  const renderTheMainContent = () => {
    return (
      <Routes key="routes">
        <Route key="hme" exact path="/" element={<HomePage />} />
        <Route key="abt" exact path="/about-us" element={<AboutPage />} />
        <Route key="sws" exact path="/swastha" element={<SwasthaPage />} />
        <Route key="prt" exact path="/portfolio" element={<PortfolioPage />} />
        <Route key="prc" exact path="/pricing" element={<PricingPage />} />
        <Route key="cnt" exact path="/contact" element={<ContactUsPage />} />
        <Route key="all" path="*" element={<HomePage />} />
      </Routes>
    );
  };

  return renderTheMainContent();
};

export default MainContent;
