import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import classNames from "classnames";
import { header, portfolio, sectionOne, sectionTwo } from "../data/aboutData";

const AboutPage = ({ active }) => {
  const renderImg = (imgSrc, imgClass, css) => {
    return <img src={imgSrc} className={imgClass} style={css} alt="" />;
  };

  const renderBgImg = (type) => {
    const bubble = require("../assets/images/system/about_s4_bubble.png");
    const bubble2 = require("../assets/images/system/about_s4_bubble2.png");
    const lines = require("../assets/images/system/about_s4_lines.png");
    const wave = require("../assets/images/system/header_4_wave.png");
    const imageItems = { bubble, bubble2, lines, wave };
    const imgSrc = imageItems[type];
    return <img src={imgSrc} alt="" className={type} />;
  };

  const renderSectionOne = (headData, data) => {
    return (
      <section className="about section-padding style-4">
        <div className="integration">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-head text-center style-4">
                <small className="title_small">{headData.moto}</small>
                <h2 className="mb-20">{headData.heading}</h2>
                <p>{headData.description}</p>
              </div>
            </div>
          </div>
        </div>

        {data.map((i, index) => {
          const d1 = classNames(
            i.imageOn === "left" ? "col-lg-6" : "col-lg-5 order-2 order-lg-0"
          );
          const d2 = classNames(
            i.imageOn === "left" ? "col-lg-5" : "col-lg-6 order-0 order-lg-2"
          );
          return (
            <div className={classNames("content", i.containerClass)}>
              <div className="container">
                <div className="row align-items-center">
                  <div className={d1}>
                    {i.imageOn === "left" ? (
                      <div className="img mb-30 mb-lg-0 round-image-container me-4">
                        {renderImg(i.mainImg)}
                      </div>
                    ) : (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-40">{i.description}</p>
                      </div>
                    )}
                  </div>
                  <div className={d2}>
                    {i.imageOn === "left" ? (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-40">{i.description}</p>
                      </div>
                    ) : (
                      <div className="img mb-30 mb-lg-0 round-image-container me-4">
                        {renderImg(i.mainImg)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {i.endImg.map((j) => renderBgImg(j))}
            </div>
          );
        })}
      </section>
    );
  };

  const renderSectionTwo = (data, usData) => {
    return (
      <section
        className="team section-padding style-6 mb-15"
        style={{ paddingBottom: "10%" }}
      >
        <div className="content">
          <div className="container">
            <div className="section-head text-center style-4 mb-60">
              <small className="title_small"> {data.moto} </small>
              <h2 className="mb-20">{data.heading}</h2>
              <p> {data.description} </p>
            </div>

            <div className="row" style={{ justifyContent: "center" }}>
              {usData.map((i) => (
                <div className="col-lg-3">
                  <div className="team-card mb-30 mb-lg-0 style-6">
                    <div className="img img-resp">
                      <img src={i.img} alt="" />
                      <div className="social-icons">
                        <a href={i.facebook} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href={i.linkedIn} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href={i.instagram} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </div>
                    </div>
                    <div className="info">
                      <a
                        className="d-block"
                        href={i.linkedIn}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h6>{i.name}</h6>
                      </a>
                      <small>{i.role}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <main className="about-page style-5">
      {renderSectionOne(header, sectionOne)}
      {renderSectionTwo(sectionTwo, portfolio)}
    </main>
  );
};

export default AboutPage;
