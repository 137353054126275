import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-regular-svg-icons";
import { header, sectionOne } from "../data/pricingData";
import { Link } from "react-router-dom";

const PricingPage = ({ active }) => {
  const renderSectionOne = (hdata, data) => {
    return (
      <section className="pricing about section-padding style-4 pb-20 pt-20">
        <div className="integration">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-head text-center style-4">
                <small className="title_small">{hdata.moto}</small>
                <h2 className="mb-20">{hdata.heading}</h2>
                <p>{hdata.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="content frs-content py-4">
          <div className="container">
            <div className="row align-items-center">
              {data.cards.map((i) => (
                <div className="col-lg-4">
                  <div className="price-card bg-white">
                    <div className="price-header pb-4">
                      <h6>
                        {i.icon && <img src={i.icon} alt="" className="icon" />}
                        {i.level}{" "}
                      </h6>
                      <h2>
                        {i.price}
                        <small> / month</small>
                      </h2>
                      <p>{i.description}</p>
                    </div>
                    <div className="price-body py-4">
                      <ul>
                        {i.points.map((j) => (
                          <li className="d-flex align-items-center mb-3">
                            <small className={i.iconClass}>
                              <FontAwesomeIcon icon={faStickyNote} />
                            </small>
                            <p className="fw-bold">{j.point}</p>
                          </li>
                        ))}
                      </ul>

                      {i.link.map((k) => (
                        <Link to={k.path} className={k.class}>
                          <small>{k.label}</small>
                        </Link>
                      ))}
                    </div>
                    {i.off && (
                      <div className="off">
                        <span>{i.off}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img
            src={require("../assets/images/system/about_s4_lines.png")}
            alt=""
            className="lines"
          />
          <img
            src={require("../assets/images/system/about_s4_bubble.png")}
            alt=""
            className="bubble"
          />
        </div>
      </section>
    );
  };

  return (
    <main className="portfolio-page style-1">
      {renderSectionOne(header, sectionOne)}
    </main>
  );
};

export default PricingPage;
