import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  header,
  sectionOne,
  sectionTwo,
  sectionThree,
  iotSection,
  sectionFour,
  sectionFive,
  sectionSix,
} from "../data/homeData";
import {
  faTag,
  faSync,
  faTextWidth,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const MainContent = ({ active }) => {
  const rating = [1, 2, 3, 4, 5];
  const icons = { faTag, faSync, faTextWidth, faStar };
  const socIcn = { faLinkedin, faTwitter, faFacebook };

  const renderLearnMore = (href) => {
    const learnMoreClass = "btn rounded-pill bg-blue4 fw-bold text-white mt-50";
    return (
      <Link className={learnMoreClass} to="/swastha">
        <small>Learn More</small>
      </Link>
    );
  };

  const renderBgImg = (type) => {
    const bubble = require("../assets/images/system/header_4_bubble.png");
    const bubble2 = require("../assets/images/system/about_s4_bubble2.png");
    const lines = require("../assets/images/system/about_s4_lines.png");
    const wave = require("../assets/images/system/header_4_wave.png");
    const imageItems = { bubble, bubble2, lines, wave };
    const imgSrc = imageItems[type];
    return <img src={imgSrc} alt="" className={type} />;
  };

  const renderImg = (imgSrc, imgClass, css) => {
    return <img src={imgSrc} className={imgClass} style={css} alt="" />;
  };

  const renderBulletList = (items, hasIcn) => {
    const renderIcon = (icn) => {
      <small className="icon-30 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-3">
        <FontAwesomeIcon icon={icons[icn]} />
      </small>;
    };

    const renderBullet = () => (
      <i className="bi bi-dot fs-2 me-2 lh-1 color-blue4"></i>
    );

    return (
      <ul>
        {items.map((i) => (
          <li className="d-flex align-items-center mb-3">
            {hasIcn ? renderIcon(i.item) : renderBullet()}
            <h6 className="fw-bold">{i.detail}</h6>
          </li>
        ))}
      </ul>
    );
  };

  const renderAccordian = (items) => {
    const accitems = items.map((data, index) => {
      const is0 = index === 0;
      const divBase = "accordion-collapse collapse";
      const divCls = classNames(divBase, is0 ? "show" : "");
      const btnClass = classNames("accordion-button", is0 ? "" : "collapsed");

      return (
        <div className="accordion-item">
          <h2 className="accordion-header" id={data.id.b}>
            <button
              className={btnClass}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${data.id.a}`}
              aria-expanded="true"
              aria-controls={data.id.a}
            >
              {data.item}
            </button>
          </h2>
          <div
            id={data.id.a}
            className={divCls}
            aria-labelledby={data.id.b}
            data-bs-parent="#dataAccordion"
          >
            <div className="accordion-body">{data.detail}</div>
          </div>
        </div>
      );
    });

    return (
      <div className="faq style-3 style-4">
        <div className="accordion" id="dataAccordion">
          {accitems}
        </div>
      </div>
    );
  };

  const btnClass = "btn rounded-pill bg-blue4 fw-bold text-white me-4";

  const renderHeadder = (data) => {
    return (
      <>
        <div className="content">
          <div className="container">
            <div className="row gx-0">
              <div className="col-lg-6">
                <div className="info">
                  <small className="mb-50 title_small">{data.moto}</small>
                  <h1 className="mb-30">{data.heading}</h1>
                  <p className="text">{data.description}</p>
                  <div className="d-flex align-items-center mt-50">
                    <Link className={btnClass} to="/contact">
                      <small> Contact Us </small>
                    </Link>
                    <Link className={btnClass} to="/swastha">
                      <small>Learn More</small>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img">{renderImg(data.headerImage)}</div>
              </div>
            </div>
          </div>
          {renderBgImg("bubble")}
        </div>
        {renderBgImg("wave")}
      </>
    );
  };

  const renderSectionOne = (data) => {
    return (
      <section className="clients style-4 top-negative-5">
        <div className="container">
          <div className="section-head text-center style-4 mb-60">
            <h2 className="mb-20">{data.heading}</h2>
          </div>
          <div className="client-logos pb-70">
            <div className="row align-items-center justify-content-center">
              {data.customers.map((item) => (
                <div key={item.id} className="col-6 col-lg-2">
                  <a href={item.href} className="img d-block">
                    {renderImg(item.imgSrc)}
                  </a>
                  <h6>{item.name}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderSectionTwo = (data) => {
    return (
      <section className="features pt-70 pb-70 style-4 top-negative-5">
        <div className="container">
          <div className="section-head text-center style-4">
            <small className="title_small bg-white">{data.title}</small>
            <h2 className="mb-70">{data.heading}</h2>
          </div>
          <div className="content">
            {data.features.map((item) => (
              <div key={item.id} className="features-card">
                <div className="icon img-contain">{renderImg(item.imgSrc)}</div>
                <h6>{item.name}</h6>
              </div>
            ))}
          </div>
        </div>
        {renderImg(data.endImg, data.endImgClass)}
      </section>
    );
  };

  const renderSectionThree = (data, iotData) => {
    return (
      <section className="about section-padding style-4 top-negative-5 pb-4">
        {data.map((i) => (
          <div className={classNames("content", i.containerClass)}>
            <div className="container">
              <div className="row align-items-center justify-content-between">
                {i.imageOn === "left" && (
                  <div className="col-lg-6">
                    <div className="img mb-30 mb-lg-0">
                      {renderImg(i.mainImg)}
                    </div>
                  </div>
                )}
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">{i.title}</small>
                      <h2 className="mb-30">{i.heading}</h2>
                    </div>
                    <p className="text mb-40">{i.description}</p>
                    {i.itemsType === "bullet"
                      ? renderBulletList(i.items)
                      : renderAccordian(i.items)}
                    {renderLearnMore(i.learnMoreLink)}
                  </div>
                </div>
                {i.imageOn === "right" && (
                  <div className="col-lg-6">
                    <div className="img mb-30 mb-lg-0">
                      {renderImg(i.mainImg)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {i.endImg.map((im) => renderBgImg(im))}
          </div>
        ))}

        <div className="integration pt-60">
          <div className="section-head text-center style-4">
            <small className="title_small">{iotData.title}</small>
            <h2 className="mb-20">{iotData.heading}</h2>
            <p>{iotData.description}</p>
          </div>
          <div className="container">
            <div className="content border-bottom">
              {iotData.imgs.map((imgData) => (
                <div className="img floating-image">
                  {renderImg(imgData.imgSrc, imgData.cls)}
                </div>
              ))}
            </div>
          </div>
          {renderImg(iotData.endImg, iotData.endImgClass)}
        </div>
      </section>
    );
  };

  const renderSectionFour = (data) => {
    return (
      <section className="testimonials style-4 top-negative-5 pt-4">
        <div className="container">
          <div className="content no-border">
            <div className="row">
              <div className="col-lg-5">
                <div className="section-head style-4">
                  <small className="title_small">{data.title}</small>
                  <h2 className="mb-30">{data.heading}</h2>
                </div>
                <p className="text mb-40">{data.description}</p>
                <div className="numbs">
                  {data.details.map((i, indx) => (
                    <div key={`${indx}_icns`} className="num-card">
                      <div className="icon img-contain">
                        {renderImg(i.icon)}
                      </div>
                      <h2>{i.h2Item}</h2>
                      <p>{i.summary}</p>
                    </div>
                  ))}
                </div>
                <div className="d-flex align-items-center mt-70">
                  {renderLearnMore(data.learnMoreLink)}
                </div>
              </div>
              <div className="col-lg-7">
                <div className="testi-cards">
                  {data.client.map((i, indx) => (
                    <div key={`${indx}_clnt`} className="client_card">
                      <div className="user_img">{renderImg(i.imgSrc)}</div>
                      <div className="inf_content">
                        <div className="stars mb-2">
                          {rating.map((j) => {
                            if (j > i.rating) return false;
                            return <FontAwesomeIcon icon={faStar} />;
                          })}
                        </div>
                        <h6>{i.comment}</h6>
                        <p>
                          {i.name}
                          <span className="text-muted">/ {i.designation}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                  {renderImg(data.endImg, data.endImgClass)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderSectionFive = (data) => {
    const { imgSrc: img1, imgClass: imgCls1, css: css1 } = data.topBgImg;
    const { imgSrc: img2, imgClass: imgCls2, css: css2 } = data.bottomBgImg;
    const aProps = {
      target: "_blank",
      className: "img d-block",
      rel: "noreferrer",
    };
    return (
      <div>
        {renderImg(img1, imgCls1, css1)}
        <section className="clients style-4 top-negative-5">
          <div className="container">
            <br />
            <br />
            <div className="section-head text-center style-4 mb-60">
              <h2 className="mb-20">{data.heading}</h2>
            </div>
            <div className="client-logos pb-70 no-border">
              <div className="row align-items-center justify-content-center">
                {data.items.map((i, indx) => (
                  <div key={`${indx}_prtner`} className="col-6 col-lg-2 my-4">
                    <a href={i.url} {...aProps}>
                      {renderImg(i.imgSrc)}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {renderImg(img2, imgCls2, css2)}
      </div>
    );
  };

  const renderSectionSix = (data) => {
    const aProps = {
      target: "_blank",
      className: "commun-card",
      rel: "noreferrer",
    };
    return (
      <section
        className="community section-padding pt-5 style-4 top-negative-5"
        style={{ paddingBottom: "10%" }}
      >
        <div className="container">
          <div className="section-head text-center style-4">
            <small className="title_small">{data.title}</small>
            <h2 className="mb-30">{data.heading}</h2>
          </div>
          <div className="content">
            {data.items.map((i) => {
              const icn = socIcn[i.icon];
              return (
                <a href={i.url} {...aProps}>
                  <div className="icon">
                    <FontAwesomeIcon icon={icn} className="brand-icon" />
                  </div>
                  <div className="inf">
                    <h5>{i.name}</h5>
                    <p>{i.summary}</p>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <header className="style-4">{renderHeadder(header)}</header>
      <main>
        {renderSectionOne(sectionOne)}
        {renderSectionTwo(sectionTwo)}
        {renderSectionThree(sectionThree, iotSection)}
        {renderSectionFour(sectionFour)}
        {renderSectionFive(sectionFive)}
        {renderSectionSix(sectionSix)}
      </main>
    </>
  );
};

export default MainContent;
