import {
  header,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
} from "../data/swasthaData";
import classNames from "classnames";

const SwasthaPage = () => {
  const renderImg = (imgSrc, imgClass, css) => {
    return <img src={imgSrc} className={imgClass} style={css} alt="" />;
  };

  const renderBgImg = (type) => {
    const bubble = require("../assets/images/system/about_s4_bubble.png");
    const bubble2 = require("../assets/images/system/about_s4_bubble2.png");
    const lines = require("../assets/images/system/about_s4_lines.png");
    const wave = require("../assets/images/system/header_4_wave.png");
    const imageItems = { bubble, bubble2, lines, wave };
    const imgSrc = imageItems[type];
    return <img src={imgSrc} alt="" className={type} />;
  };

  const renderSectionOne = (hdata, fdata) => {
    return (
      <section className="about section-padding style-4 pb-20 pt-20">
        <div className="content frs-content py-4">
          <div className="container full-width-container">
            <div className="integration">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="section-head text-center style-4">
                    <small className="title_small">{hdata.moto}</small>
                    <h2 className="mb-20">{hdata.heading}</h2>
                    <img
                      style={{ height: "100px", margin: "0 0 20px 0" }}
                      src={hdata.logo}
                      alt=""
                    />
                    <p>{hdata.description}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="content swastha-features-container">
              <div className="container">
                <div className="section-head text-center style-4 mb-60">
                  <h2 className="mb-20">{hdata.heading}</h2>
                </div>
                <div className="row">
                  {hdata.keyPoints.map((point) => (
                    <div className="col-lg-6">
                      <div className="team-card mb-30 mb-lg-0 style-6">
                        <div className="img img-cover flex-center-solutions">
                          <img src={point.imgSrc} alt="" />
                        </div>
                        <div className="info">
                          <h6>{point.title}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <img
            src={require("../assets/images/system/about_s4_lines.png")}
            alt=""
            className="lines"
          />
          <img
            src={require("../assets/images/system/about_s4_bubble.png")}
            alt=""
            className="bubble"
          />
        </div>

        <div className="integration">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-head text-center style-4">
                <h2 className="mb-20">{fdata.heading}</h2>
                {fdata.description}
              </div>
            </div>
          </div>
        </div>

        {fdata.features.map((i, index) => {
          const d1 = classNames(
            i.imageOn === "left" ? "col-lg-6" : "col-lg-5 order-2 order-lg-0"
          );
          const d2 = classNames(
            i.imageOn === "left" ? "col-lg-5" : "col-lg-6 order-0 order-lg-2"
          );
          return (
            <div className={classNames("content", i.containerClass)}>
              <div className="container">
                <div className="row align-items-center">
                  <div className={d1}>
                    {i.imageOn === "left" ? (
                      <div className="img mb-30 mb-lg-0 round-image-container product-img-height me-4">
                        {renderImg(i.mainImg, "", { height: "300px" })}
                      </div>
                    ) : (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-40">{i.description}</p>
                      </div>
                    )}
                  </div>
                  <div className={d2}>
                    {i.imageOn === "left" ? (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-40">{i.description}</p>
                      </div>
                    ) : (
                      <div className="img mb-30 mb-lg-0 round-image-container product-img-height me-4">
                        {renderImg(i.mainImg, "", { height: "300px" })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {i.endImg.map((j) => renderBgImg(j))}
            </div>
          );
        })}
      </section>
    );
  };

  const renderSectionTwo = (data) => {
    return (
      <section className="about section-padding pt-20 style-4 pb-20">
        <div className="integration">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-head text-center style-4">
                <h2 className="mb-20">{data.heading}</h2>
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        </div>

        {data.features.map((i, index) => {
          const d1 = classNames(
            i.imageOn === "left" ? "col-lg-6" : "col-lg-5 order-2 order-lg-0"
          );
          const d2 = classNames(
            i.imageOn === "left" ? "col-lg-5" : "col-lg-6 order-0 order-lg-2"
          );
          return (
            <div className={classNames("content", i.containerClass)}>
              <div className="container">
                <div className="row align-items-center">
                  <div className={d1}>
                    {i.imageOn === "left" ? (
                      <div className="img mb-30 mb-lg-0 round-image-container product-img-height me-4">
                        {renderImg(i.mainImg)}
                      </div>
                    ) : (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-20">{i.description}</p>
                        <b> {i.fheading} </b>
                        {i.content}
                      </div>
                    )}
                  </div>
                  <div className={d2}>
                    {i.imageOn === "left" ? (
                      <div className="info">
                        <div className="section-head style-4">
                          <h2 className="mb-30">{i.heading}</h2>
                        </div>
                        <p className="text mb-20">{i.description}</p>
                        <b> {i.fheading} </b>
                        {i.content}
                      </div>
                    ) : (
                      <div className="img mb-30 mb-lg-0 round-image-container product-img-height me-4">
                        {renderImg(i.mainImg)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {i.endImg.map((j) => renderBgImg(j))}
            </div>
          );
        })}
      </section>
    );
  };

  const renderSectionThree = (data) => {
    return (
      <div className="mb-50">
        <img
          src={require("../assets/images/system/about_s4_wave.png")}
          alt=""
          className="top-wave"
          style={{
            transform: "rotate(180deg)",
            width: "100%",
            bottom: "-2px",
            position: "relative",
          }}
        />
        <section className="clients style-4 pt-20">
          <div className="container pt-20">
            <div className="section-head text-center style-4 mb-60">
              <h2 className="mb-20">{data.heading}</h2>
            </div>
            <div className="download-brochure pb-70">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-12 button-center">
                  <a
                    href="assets/Swastha Brochure.pdf"
                    className="btn rounded-pill bg-blue4 fw-bold text-white me-4"
                    target="_blank"
                  >
                    <small> {data.content} </small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <img
          src={require("../assets/images/system/about_s4_wave.png")}
          alt=""
          className="top-wave"
          style={{
            width: "100%",
            top: "-4px",
            position: "relative",
          }}
        />
      </div>
    );
  };

  const renderSectionFour = (data) => {
    return (
      <section className="about section-padding pt-20 style-4 pb-20">
        <div className="screenshots style-4 new-screen-shots pt-10">
          <div className="screenshots-slider style-4">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {data.imgData.map((i) => (
                  <div className="swiper-slide">
                    <div className="img">
                      <img src={i.img} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <img src={data.mainImg} alt="" className="mob-hand" />
        </div>
      </section>
    );
  };

  return (
    <main className="portfolio-page style-5">
      {renderSectionOne(header, sectionOne)}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <hr style={{ width: "50%", color: "#A8A9AF" }}></hr>
      </div>
      {renderSectionTwo(sectionTwo)}
      {renderSectionThree(sectionThree)}
      {renderSectionFour(sectionFour)}
    </main>
  );
};

export default SwasthaPage;
