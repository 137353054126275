import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const NavBar = ({ active, onNavigate }) => {
  const location = useLocation();
  const productRef = useRef();

  const getClassNames = (defaults = "", key) => {
    const currentPath = getCutrrentPage();
    if (productRef && productRef.current && productRef.current.classList) {
      if (currentPath === "/swastha") {
        productRef.current.classList.add("active");
      } else {
        productRef.current.classList.remove("active");
      }
    }
    return classNames(defaults, currentPath === `/${key}` ? "active" : "");
  };

  const getCutrrentPage = () => {
    return location.pathname;
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light style-4">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={require("../../assets/images/logos/starbase.png")} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
            <li className="nav-item">
              <Link className={getClassNames("nav-link", "")} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={getClassNames("nav-link", "about-us")}
                to="/about-us"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                ref={productRef}
                className="nav-link dropdown-toggle"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ margin: "0 10px" }}
                />
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link className="dropdown-item" to="/swastha">
                    Swastha 1.0
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={getClassNames("nav-link", "portfolio")}
                to="/portfolio"
              >
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={getClassNames("nav-link", "pricing")}
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={getClassNames("nav-link", "contact")}
                to="/contact"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
