import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

const Footer = ({ active, onNavigate }) => {
  const location = useLocation();

  const getClassNames = (key) => {
    const currentPath = getCutrrentPage();
    return classNames(currentPath === `/${key}` ? "active" : "");
  };

  const getCutrrentPage = () => {
    return location.pathname;
  };

  return (
    <footer className="style-4 mt-0">
      <div className="container">
        <div className="foot mt-80">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="logo">
                <img src="assets/img/logo_lgr.png" alt="" />
              </div>
            </div>
            <div className="col-lg-8">
              <ul className="links">
                <ul className="links">
                  <li>
                    <Link className={getClassNames("")} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className={getClassNames("about-us")} to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className={getClassNames("swastha")} to="/swastha">
                      Swastha 1.0
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={getClassNames("portfolio")}
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link className={getClassNames("pricing")} to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link className={getClassNames("contact")} to="/contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </ul>
            </div>
            <div className="col-lg-2">
              <div className="dropdown">
                <button
                  className="icon-25 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={require("../../assets/images/icons/lang.png")}
                    alt=""
                    className="me-2"
                  />
                  <small>English</small>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      English
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Arabic
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite text-center">
          <small className="small">
            © 2022 Copyrights by{" "}
            <a href="#" className="fw-bold text-decoration-underline">
              Teamstarbase Pvt Ltd
            </a>{" "}
            All Rights Reserved. Designed by{" "}
            <a
              href="page-about-app.html"
              className="fw-bold text-decoration-underline"
            >
              Teamstarbase Pvt Ltd
            </a>
          </small>
        </div>
      </div>
      <div>
        <img
          src={require("../../assets/images/system/footer_4_wave.png")}
          alt=""
          className="wave"
          style={{ bottom: "90%" }}
        />
      </div>
    </footer>
  );
};

export default Footer;
